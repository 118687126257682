<template>
    <div :class="{mobile: isMobile}">
        <div class="top mb-3">
            <div class="title">{{ $t("score_histroy") }}</div>
           
        </div>
        <div class="mb-5 uptickAddress">{{ $t('uptick_address')}} {{ uptickAddress}}</div>
        <div class="number">
            <div class="item">
                <div class="back">
                    <span class="s1">{{$t('score_total_claim')}}</span>
                    <span class="s2">{{scoreTotalInfo.scoreNumber ?scoreTotalInfo.scoreNumber :0}}</span>
                </div>
            </div>
           
            <div class="item">
                <div class="back">
                    <span class="s1">{{$t('score_total_uptick')}}</span>
                    <span class="s2">{{scoreTotalInfo.scoreUptick ?scoreTotalInfo.scoreUptick :0}}</span>
                </div>
            </div>
        </div>
        <div class="list" v-if="list.length > 0">
             <div class=" titlerow ">
                <span class="item s1">{{$t('pointsCenter_Points')}}</span>
                <span class="item s2">UPTICK</span>
                <span class="item s3">{{$t('detailIndexTime')}}</span>
                <span class="item s4">{{$t('status')}}</span>
            </div>

            <div class="row" v-for="(item, idx) in list" :key="idx">
                <span class="item s1">{{item.number}} </span>
                <span class="item s2">{{item.auptick}}</span>
                <span class="item s3"> {{timestampToDateTime(item.createTime)}}</span>
                 <span class="item s4" :class="{s5:item.status == 1}">{{getStatus(item.status)}}</span>
            </div>
        </div>
        <loading :isShowLoading="isShowLoading" ></loading>

        <promptBox ref="confirm" @commit="exchangeConfirm"></promptBox>
        <uToast ref="toast"></uToast>
    </div>
</template>

<script>
    import api from "@/api";
    import Loading from "@/components/loading.vue";
    import { getUptickAddress } from "../../metaMask/evm/index";

    
    export default {
        components: { Loading },
        data() {
            return {
                uptickAddress:'',
                // scroe_task_title: 0,
                scroe_task_consumer: 0,
                scroe_task_creator: 0,
                list: [
              
                ],
                isShowHistroy:false,
                pageNumber: 1,
                totalPage: 0,
                
                isShowLoading: false,
                loadingTimer: 0,

                totalScore: 0,
                scoreLimit: 1,
                currentPoints: 0,
                currentProgress: 0,

                scoreRate: 0,
                progressWidth: 0,
                isshow:false,
                iconBalance:[],
                lang:'',
                scoreTotalInfo:{}
            }
        },
        computed: {
            isMobile: function() {
                let mobile = this.$vuetify.breakpoint.mobile;
                return mobile;
            }
        },
        mounted() {
            window.eventBus.$on('LangChange', this.onLangChange);
            window.onscroll = this.onScroll;
            
            this.progressWidth = this.isMobile ? 240 : 403;

            this.init();
        },
        methods: {
            async init() {

                let param = { address: this.$store.state.did };
        let res = await api.home.loopringUserInfo(param);
        // this.uptickAddress = res.data.uptickAddress
         // 获取uptick地址

         
            let uptickAddress =  await api.home.getUptickAddress({address:res.data.address})
            this.uptickAddress = uptickAddress.data
            console.log("uptickAddress",this.uptickAddress );
            debugger
              // 获得圈存记录 scoreTotalInfo
            let scoreTotalInfo =   await api.home.scoreTotalInfo({address: this.$store.state.did})
            this.scoreTotalInfo = scoreTotalInfo.data;
           
            this.getList();
       
            },
            async getList() {
                let res4 = await api.home.scoreList({
                     address: this.$store.state.did,
                    pageSize: 10,
                    pageNumber: this.pageNumber,
                    lang:this.lang
                });
                this.list = [...this.list, ...res4.data.list];
                this.totalPage = res4.data.totalPage;
            },
            onScroll() {
                let scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
                let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                let scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
                
                if (!this.isShowLoading && scrollTop + windowHeight >= scrollHeight && this.totalPage > this.pageNumber) {
                    clearTimeout(this.loadingTimer);
                    this.loadingTimer = setTimeout(() => {
                        this.isShowLoading = true;
                        this.pageNumber++;
                        this.getList();
                    }, 400);
                } else {
                    this.isShowLoading = false;
                }
            },
            onLangChange(language) {
                this.lang = language
                this.list = [];
                this.pageNumber = 1;
                this.getList();
            },
             moused() {
                            console.log("22222");

                this.isshow = true;
                },
                leave() {
                    console.log("33333");
                    this.isshow = false
                
                },
                historymoused(){
                    this.isShowHistroy = true
                },
                 historyleave(){
                     this.isShowHistroy = false

                },
                toHistroy(){
                    this.$router.push({name:'ClaimHistroy'})
                },
            exchange() {
                this.$refs.confirm.show(this.$t("pointsCenter_exchange")+" "+this.currentPoints+" "+this.$t("pointsCenter_Points")+" ");
            },
            async exchangeConfirm() {
                let res1 = await api.home.scoreExchange({
                     address: this.$store.state.did
                });
                this.$refs.toast.show("success", res1.msg);
                this.init();
            },
            getStatus(status){
                switch (status) {
                    case 1:
                        return this.$t("score_distributing")
                    case 2:
                        return this.$t("score_receive")
                    default:
                        return 'Failed'  
                  
                
                    
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.uptickAddress{
             font-family: Helvetica;
              font-size: 12px;
              color: #766983;
        }
    .top {
        display: flex;
        margin-bottom: 10px;
        .title {
            width: 300px;
            font-family: Helvetica;
            font-weight: bold;
            font-size: 25px;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
        }
        
        .right {
               position: relative;
            margin-left: auto;
            display: flex;
            align-items: center;
            .points {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                .num1 {
                      font-family: Helvetica;
                      font-weight: bold;
                    font-size: 14px;
                    color: #924eff;
                }
                .num2 {
                     font-family: Helvetica;
                    font-size: 12px;
                    color: #270645;
                }
                .progress {
                    width: 403px;
                    height: 6px;
                    background-image: linear-gradient(
                        #766983, 
                        #766983), 
                    linear-gradient(
                        #f1f0f2, 
                        #f1f0f2);
                    background-blend-mode: normal, 
                        normal;
                    border-radius: 3px;
                    margin: 3px 0px;
                    overflow: hidden;
                    .cg {
                        height: 6px;
                        background-image: linear-gradient(90deg, 
                            #d300fd 0%, 
                            #a439fe 26%, 
                            #7471ff 52%, 
                            #00fdcf 100%), 
                        linear-gradient(
                            #f1f0f2, 
                            #f1f0f2);
                        background-blend-mode: normal, 
                            normal;
                        border-radius: 3px;
                    }
                }
            }
            .showHistroy{
                    position: absolute;
                    z-index: 100;
                    top: 48px;
                    right: 0;
                    width: 110px;
                    height: 41px;
                    background-color: #270645;
                    border-radius: 3px;
                	font-family: Helvetica;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    line-height: 20px;
                    letter-spacing: 0px;
                    color: #ffffff;
                    justify-content: center;
            }
            .btn-claim {
                width: 121px;
                height: 41px;
                background-color: #270645;
                border-radius: 20px;
                text-align: center;
                margin-left: 15px;
                user-select: none;
                cursor: pointer;

                span {
                    font-family: Helvetica;
                      font-weight: bold;
                    font-size: 15px;
                    line-height: 41px;
                    color: #ffffff;
                }
            }
            .btn-claim-grey {
                background-color: #766983;
                cursor: unset !important;
            }
        }
    }
    

    .number {
        display: flex;
        justify-content: space-between;
        height: 160px;

        .item {
            width: 600px;
            height: 116px;
            border:2px solid #1d42ff;
            border-radius: 18px;
            position: relative;

            .back {
                position: absolute;
                left: 2px;
                top: 2px;
                right: 2px;
                bottom: 2px;
                background-color: #FFFFFF;
                border-radius: 18px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .s1 {
                     font-family: Helvetica;
                      font-weight: bold;
                    font-size: 14px;
                    color: #270645;
                }

                .s2 {
                     font-family: Helvetica;
                      font-weight: bold;
                    font-size: 25px;
                    color: #1d42ff;
                }
            }
                   .explain {
      width: 236px;
      background-image: linear-gradient(
		#ffffff, 
		#ffffff), 
	linear-gradient(
		#6f58d9, 
		#6f58d9);
        background-blend-mode: normal, 
		normal;
	box-shadow: 0px 0px 9px 0px 
		rgba(0, 0, 0, 0.09);
      border-radius: 5px;
      position: absolute;
      top: 80px;
      right: -70px;
      z-index:1;
      .all{
          margin: 17px;
           .iris{
         display: flex;
        flex-direction: row; 
        span{
        font-family: Helvetica;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        color: #270645;
        }
      }

      }
     
    }
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;

        .row {
            width: 100%;
            height: 61px;
            background-image: linear-gradient(#ffffff,
                    #ffffff),
                linear-gradient(#6f58d9,
                    #6f58d9);
            background-blend-mode: normal,
                normal;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            .s1 {
                width: 25%;
                max-width: 25%;
                  font-family: Helvetica;
                font-weight: bold;
                font-size: 14px;
                color: #270645;
                font-weight: bold;
            }

            .s2 {
                width: 25%;
                max-width: 25%;
                font-family: Helvetica;
                font-weight: bold;
                font-size: 14px;
                color: #1d42ff;
            }
            .s3 {
                width: 25%;
                max-width: 25%;
                  font-family: Helvetica;
                      font-weight: bold;
                font-size: 14px;
             
                color: #270645;
            }
             .s4 {
                width: 10%;
                max-width: 10%;
                font-family: Helvetica;
                font-weight: bold;
                font-size: 14px;
                color: #270645;
            }
             .s5 {
                width: 10%;
                max-width: 10%;
                font-family: Helvetica;
                font-weight: bold;
                font-size: 14px;
                color: #999999;;
            }
        }
        .titlerow {
            width: 100%;
            height: 61px;
            background-image: linear-gradient(#ffffff,
                    #ffffff),
                linear-gradient(#6f58d9,
                    #6f58d9);
            background-blend-mode: normal,
                normal;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            .s1 {
                width: 25%;
                max-width: 25%;
                font-family: Helvetica;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                line-height: 20px;
                letter-spacing: 0px;
                color: #270645;
            }

            .s2 {
                width: 25%;
                max-width: 25%;
                font-family: Helvetica;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                line-height: 20px;
                letter-spacing: 0px;
                color: #270645;
            }
            .s3 {
                width: 25%;
                max-width: 25%;
                font-family: Helvetica;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                line-height: 20px;
                letter-spacing: 0px;
                color: #270645;
            }
             .s4 {
                width: 25%;
                max-width: 10%;
                 font-family: Helvetica;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                line-height: 20px;
                letter-spacing: 0px;
                color: #270645;
            }
        }
    }

    .mobile {
        margin-top: -20px;
        .top {
            flex-direction: column;
            .btn-claim {
                width: 100px;
            }
        }
        
        .title {
            font-family: Helvetica;
            font-weight: bold;
            font-size: 15px;
            color: #270645;
            line-height: 80px;
        }

        .number {
            height: 90px;

            .item {
                width: 115px;
                height: 65px;

                .back {
                    .s1 {
                        font-size: 9px;
                        white-space: nowrap;
                    }

                    .s2 {
                        font-size: 14px;
                    }
                }
            }
        }

        .list {
            .row {
                width: 100%;

                .s2 {
                    width: 40%;
                }
                
                .s3 {
                    width: 10%;
                    max-width: 10%;
                }
                
            }
        }
    }
</style>
